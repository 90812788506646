import BtnLeermas from "../buttons/BtnLeermas";

interface Props {
    text: string
    btn: string
    theme1: string;
    theme2: string;
    link: string;
}
const CardHeroBlog = ({ text, btn, theme1, theme2, link }: Props) => {
    return (
        <div
            className="relative bg-cover bg-center rounded-2xl h-full overflow-hidden z-20
             before:w-full before:h-full before:bg-gradient-to-r from-black to-transparent before:opacity-60 before:z-10 before:absolute"
            style={{ backgroundImage: 'url(/blog/glosario-ux/libro_ux_ciclosstudio.jpg)' }}>
            <div className="flex flex-col max-w-[570px]
                    px-[16px] pt-[120px] pb-[28px]
                    md:p-[32px] md:pb-[40px]">
                <h1 className=" text-left text-white font-jost
                    text-[18px] } leading-[20px]
                    sm:text-[36px] sm:leading-[40px]
                    md:leading-[48px] md:text-[40px] z-20">
                    {text}
                </h1>
                <div className="flex gap-[15px] max-w-[150px] bg-white bg-opacity-25 justify-center md:mt-7 mt-2 rounded-2xl z-20">
                    <p className="text-[12px] font-medium sm:font-bold text-white">{theme1}</p>
                    <p className="text-[12px] font-medium sm:font-bold text-white">{theme2}</p>
                </div>
                <div className="z-20 mt-7 md:mt-[58px]">
                    <BtnLeermas link={link} text={btn} />
                </div>
            </div>
        </div>

    );



}

export default CardHeroBlog