import { Outlet } from 'react-router-dom'
import Navbar from '../shared/Navbar'
import BtnWhatsApp from '../components/buttons/BtnWhatsApp'

interface Props {
    changeLanguaje: () => void,
    data: any
}

const PrincipalRoute = ({ changeLanguaje, data }: Props) => {
    return (
        <div className=''>
            <Navbar data={data} changeLanguaje={changeLanguaje} />
            <Outlet />
            <BtnWhatsApp data={data} />
        </div>
    )
}

export default PrincipalRoute