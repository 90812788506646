import Logo from '../svgs/Logo'

const Splash = () => {
    return (
        <div
            className='flex justify-center items-center h-[100vh] bg-white z-20'>
            <Logo />
        </div>
    )
}

export default Splash