import BlogStructure from "../../components/structure/BlogStructure"
import H3text from "../../components/structure/sections/H3text"
import Text from "../../components/structure/sections/Text"

interface Props {
    data: any
}
const Futuro = ({ data }: Props) => {
    const pl = "sm:pl-[38px]"
    return (
        <BlogStructure data={data} maxW="max-w-[700px]"
            bio={data.futuro.bio}
            title={data.futuro.hero.title}
            hero={data.futuro.galery.hero}
            althero={data.futuro.galery.althero}
            text1={data.futuro.hero.text1}
            text2={data.futuro.hero.text2}>
            <H3text h3={data.futuro.h3text1.title}
                text={data.futuro.h3text1.text} pl={pl} />
            <img 
                alt={data.futuro.galery.alt1} 
                src={data.futuro.galery.img1} />
            <H3text h3={data.futuro.h3text2.title}
                text={data.futuro.h3text2.text} pl={pl} />
            <img 
                alt={data.futuro.galery.alt2}
                src={data.futuro.galery.img2} />
            <H3text h3={data.futuro.h3text3.title}
                text={data.futuro.h3text3.text} pl={pl} />
            <img 
                alt={data.futuro.galery.alt3}
                src={data.futuro.galery.img3} />
            <H3text h3={data.futuro.h3text4.title}
                text={data.futuro.h3text4.text} pl={pl} />
            <img 
                alt={data.futuro.galery.alt4}
                src={data.futuro.galery.img4} />
            <H3text h3={data.futuro.h3text5.title}
                text={data.futuro.h3text5.text} pl={pl} />
            <img 
                alt={data.futuro.galery.alt5} 
                src={data.futuro.galery.img5} />
            <H3text h3={data.futuro.h3text6.title}
                text={data.futuro.h3text6.text} pl={pl} />
            <img 
                alt={data.futuro.galery.alt6}
                src={data.futuro.galery.img6} />
            <H3text h3={data.futuro.h3text6.title}
                text={data.futuro.h3text7.text} pl={pl} />
            <img 
                alt={data.futuro.galery.alt7}
                src={data.futuro.galery.img7} />
            <Text text={data.futuro.resumen} />
        </BlogStructure>
    )
}

export default Futuro

