import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import CardBlog from "../cards/CardBlog";

export default function BlogSwiper({ data }) {
  return (
    <>
      <Swiper
        style={{
          "--swiper-pagination-color": "#FF0062",
          "--swiper-pagination-bullet-inactive-color": "#E7F0FC",
          "--swiper-pagination-bullet-inactive-opacity": "1",
          ".swiper-pagination-bullet": "swiper-pagination-bullet"
        }}
        breakpoints={{
          1040: {
            maxWidth: "1200px",
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 24,
          },
          640: {
            maxWidth: "640px",
            slidesPerView: 2.2,
            slidesPerGroup: 1,
            spaceBetween: 24,
          },
          350: {
            slidesPerView: 1.5,
            slidesPerGroup: 1,
            spaceBetween: 16,
          },
          104: {
            slidesPerView: 1.2,
            slidesPerGroup: 1,
            spaceBetween: 16,
          },
        }}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
          <CardBlog
            title={data.estrategias.hero.title}
            img={data.estrategias.galery.hero}
            text={data.pageBlog.theme1}
            link="advertising-strategies-increase-sales"
          />
        </SwiperSlide>
        <SwiperSlide>
          <CardBlog
            title={data.glosario.hero.title}
            img={data.glosario.galery.hero}
            text={data.pageBlog.theme1}
            link="glossary-terminology-ux-ui-design"
          />
        </SwiperSlide>
        <SwiperSlide>
          <CardBlog
            title={data.impulsar.hero.title}
            img={data.impulsar.galery.hero}
            text={data.pageBlog.theme1}
            link="strategies-boost-grow-online-business"
          />
        </SwiperSlide>
        <SwiperSlide>
          <CardBlog
            title={data.importancia.hero.title}
            img={data.importancia.galery.hero}
            text={data.pageBlog.theme1}
            link="user-experience-importance-ux-ui"
          />
        </SwiperSlide>
        <SwiperSlide>
          <CardBlog
            title={data.asesoramiento.hero.title}
            img={data.asesoramiento.galery.hero}
            text={data.pageBlog.theme1}
            link="digital-consulting-risks-strategy"
          />
        </SwiperSlide>
        <SwiperSlide>
          <CardBlog
            title={data.futuro.hero.title}
            img={data.futuro.galery.hero}
            text={data.pageBlog.theme1}
            link="ux-design-trends-xr-ai-multi-device"
          />
        </SwiperSlide>
        <SwiperSlide>
          <CardBlog
            title={data.innovacion.hero.title}
            img={data.innovacion.galery.hero}
            text={data.pageBlog.theme1}
            link="success-cases-netflix-zoom-tesla"
          />
        </SwiperSlide>
        <SwiperSlide>
          <CardBlog
            title={data.redefiniendo.hero.title}
            img={data.redefiniendo.galery.hero}
            text={data.pageBlog.theme1}
            link="prototips-online-education-ux-ui"
          />
        </SwiperSlide>
      </Swiper>
    </>
  );
}