import { motion } from "framer-motion"

function Logo() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="255"
            height="39"
            fill="none"
            viewBox="0 0 255 39"
            className="scale-75 sm:scale-100 md:scale-[1.3]"
        >
            <g>
                <motion.path
                    animate={{ opacity: 1 }}
                    initial={{ opacity: 0.3 }}
                    transition={{ duration: 0.75, delay: 0.5, easings: "easeInCirc" }}
                    fill="#2B2326"
                    d="M92.234 6.668c3.723 0 7.232 1.647 9.655 4.493a1.773 1.773 0 002.511.197 1.793 1.793 0 00.196-2.524C101.497 5.182 96.99 3.07 92.234 3.07c-8.96 0-16.247 7.321-16.247 16.324 0 9.003 7.286 16.324 16.247 16.324 4.756 0 9.263-2.094 12.362-5.763a1.793 1.793 0 00-.196-2.524 1.773 1.773 0 00-2.511.197 12.61 12.61 0 01-9.655 4.492c-6.983 0-12.684-5.71-12.684-12.744s5.7-12.708 12.684-12.708z"
                ></motion.path>
                <motion.path
                    animate={{ opacity: 1 }}
                    initial={{ opacity: 0.3 }}
                    transition={{ duration: 0.75, delay: 0.75, easings: "easeInCirc" }}
                    fill="#2B2326"
                    d="M117.938 3.088c-.979 0-1.781.805-1.781 1.79v29.05c0 .984.802 1.79 1.781 1.79.98 0 1.782-.806 1.782-1.79V4.878c0-.985-.802-1.79-1.782-1.79z"
                ></motion.path>
                <motion.path
                    animate={{ opacity: 1 }}
                    initial={{ opacity: 0.3 }}
                    transition={{ duration: 0.75, delay: 1, easings: "easeInCirc" }}
                    fill="#2B2326"
                    d="M147.064 6.668c3.723 0 7.232 1.647 9.655 4.493a1.773 1.773 0 002.511.197 1.793 1.793 0 00.196-2.524c-3.099-3.652-7.606-5.764-12.362-5.764-8.961 0-16.247 7.321-16.247 16.324 0 9.003 7.286 16.324 16.247 16.324a16.13 16.13 0 0012.362-5.763 1.793 1.793 0 00-.196-2.524 1.773 1.773 0 00-2.511.197 12.61 12.61 0 01-9.655 4.492c-6.983 0-12.684-5.71-12.684-12.744s5.701-12.708 12.684-12.708z"
                ></motion.path>
                <motion.path
                    animate={{ opacity: 1 }}
                    initial={{ opacity: 0.3 }}
                    transition={{ duration: 0.75, delay: 1.25, easings: "easeInCirc" }}
                    fill="#2B2326"
                    d="M183.814 32.138h-9.263V4.878c0-.985-.802-1.79-1.781-1.79-.98 0-1.782.805-1.782 1.79v29.05c0 .984.802 1.79 1.782 1.79h11.044c.98 0 1.782-.806 1.782-1.79 0-.985-.802-1.79-1.782-1.79z"
                ></motion.path>
                <motion.path
                    animate={{ opacity: 1 }}
                    initial={{ opacity: 0.3 }}
                    transition={{ duration: 0.75, delay: 1.5, easings: "easeInCirc" }}
                    delay={1}
                    fill="#2B2326"
                    d="M208.344 3.088c-8.96 0-16.246 7.32-16.246 16.324 0 9.003 7.286 16.324 16.246 16.324 8.96 0 16.246-7.321 16.246-16.324 0-9.003-7.286-16.324-16.246-16.324zm0 29.05c-6.983 0-12.684-5.71-12.684-12.744S201.343 6.65 208.344 6.65c7.001 0 12.683 5.71 12.683 12.744s-5.682 12.744-12.683 12.744z"
                ></motion.path>
                <motion.path
                    animate={{ opacity: 1 }}
                    initial={{ opacity: 0.3 }}
                    transition={{ duration: 0.75, delay: 1.75, easings: "easeInCirc" }}
                    fill="#2B2326"
                    d="M252.183 19.143c-1.497-1.503-3.492-2.56-5.416-3.598-3.242-1.718-5.201-2.9-5.201-4.779 0-.053 0-.143-.018-.179 0-.053-.089-1.378 1.158-2.63a4.34 4.34 0 016.146 0 1.767 1.767 0 002.511 0 1.788 1.788 0 000-2.525c-3.081-3.096-8.087-3.096-11.187.018-2.262 2.274-2.244 4.78-2.191 5.442.054 4.116 3.795 6.103 7.108 7.857 1.692.895 3.456 1.826 4.578 2.954 2.369 2.38 2.369 6.264 0 8.663-2.369 2.38-6.235 2.38-8.622 0a6.137 6.137 0 01-1.763-4.833 1.81 1.81 0 00-1.639-1.933 1.796 1.796 0 00-1.924 1.647 9.66 9.66 0 002.797 7.642 9.56 9.56 0 006.822 2.828 9.605 9.605 0 006.823-2.828c3.777-3.812 3.777-9.951.018-13.746z"
                ></motion.path>
                <svg width="100" height="100">
                    <defs>
                        <linearGradient id="gradient" x1="100%" y1="0%" x2="0%" y2="0%">
                            <stop offset="0%" stopColor="#FF0062" />
                            <stop offset="100%" stopColor="#FF0062" />
                        </linearGradient>
                    </defs>
                    <motion.path
                        animate={{ opacity: 1 }}
                        initial={{ opacity: 0.3 }}
                        transition={{ duration: 2, delay: 1 }}
                        d="M31.933 5.683c-2.992 3.007-5.112 7.016-7.143 10.9-3.42 6.498-5.771 10.453-9.512 10.453-.09 0-.268.018-.375.018-.106 0-2.743.197-5.237-2.326-3.384-3.401-3.402-8.95-.018-12.35a3.599 3.599 0 000-5.066 3.558 3.558 0 00-5.04 0c-6.147 6.193-6.147 16.27.017 22.463 4.525 4.546 9.512 4.493 10.813 4.403 8.194-.107 12.149-7.625 15.64-14.265 1.8-3.42 3.652-6.945 5.879-9.183 4.756-4.779 12.487-4.779 17.226 0 4.738 4.78 4.756 12.53 0 17.309a12.064 12.064 0 01-9.602 3.526 3.549 3.549 0 00-3.83 3.275 3.555 3.555 0 003.26 3.849 19.255 19.255 0 0015.213-5.603 19.402 19.402 0 005.647-13.71c0-4.976-1.888-9.934-5.647-13.729-7.517-7.535-19.773-7.535-27.29.036z"
                        style={{ fill: "url(#gradient)" }}
                    />
                </svg>
            </g>
        </svg>
    );
}

export default Logo;