import { Outlet } from 'react-router-dom'
import NavbarBlog from '../shared/NavbarBlog'

interface Props {
    changeLanguaje: () => void,
    data: any
 }

const BlogRoutes = ({ changeLanguaje, data }: Props) => {
    return (
        <div className=''>
            <NavbarBlog data={data} changeLanguaje={changeLanguaje} />
            <Outlet />
        </div>
    )
}

export default BlogRoutes