import BtnReturn from "../buttons/BtnToReturn"
import BtnToTop from "../buttons/BtnToTop"
import SectionAplicar from "./SectionAplicar"
import SectionFinal from "./SectionFinal"
import { Helmet } from "react-helmet";
import { motion } from "framer-motion"

interface Props {
    maxW?: string,
    aplicar?: boolean,
    title: string,
    bio: string,
    description?: string,
    hero: string,
    althero?: string,
    text1: string,
    text2?: string,
    children: React.ReactNode,
    data: any
};

const BlogStructure = ({ title, description, hero, althero, text1, text2, children, data, maxW, aplicar, bio }: Props) => {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.5 }}
            className="py-[40px] auxNav:py-[56px] sm:py-[80px] relative">
            <BtnReturn />
            <Helmet>
                <title>{title + "- Blog by Ciclos Studio"}</title>
                <meta name="description" content={bio} />
            </Helmet>
            <section id="Inicio" className="relative">
                <div
                    className="grid max-w-[850px] mx-auto px-[5%]
                    sm:px-[6%] lg:px-0">
                    <h2 className={`text-center select-none mx-auto !z-20 ${maxW}`}>{title}</h2>
                    {
                        description &&
                        <p
                            className="font-sans text-center pt-[12px] sm:pt-[24px] !z-20
                        text-[14px] leading-[160%] 
                        sm:text-[24px] sm:leading-[28px]" >
                            {description}
                        </p>
                    }
                </div>
                <img src="/blog/decorations/circle1.svg" alt="" className="absolute translate-x-[50%] right-[40%] top-[200%] sm:top-[10%] z-10" />
                <img src="/blog/decorations/circle-mini.svg" alt="" className="absolute translate-x-[50%] right-[10%] top-[10%] sm:top-[90%] sm:right-[34%] z-10" />
                <img src="/blog/decorations/dots.svg" alt="" className="absolute top-[50%] sm:top-[80%] translate-x-[50%] right-[74%] z-10" />
            </section>
            <section className="relative">
                <div
                    className="containerBlog2 grid gap-[24px] py-[18px]
                    sm:gap-[32px] sm:py-[40px]">
                    <img src={hero} alt={althero}
                        className="object-cover !z-20
                        w-full h-[160px]
                        md:w-full md:h-auto">
                    </img>
                    <p
                        className="font-sans font-medium !z-20
                        text-[14px] leading-[150%]
                        sm:text-[20px] sm:leading-[28px] sm:text-center" >
                        {text1}
                        {
                            text2 &&
                            (<>
                                <br /><br />
                                {text2}
                            </>)
                        }
                    </p>
                </div>
                <img src="/blog/decorations/rectangle-red.svg" alt="" className="absolute top-[82%] translate-x-[50%] right-[28%] !z-10" />
                <img src="/blog/decorations/dots.svg" alt="" className="absolute bottom-[-120%] sm:bottom-[-60%] sm:translate-x-[90%] z-10" />
            </section>
            <section className="relative">
                <div className="container-article !z-20 relative
                    grid gap-[16px] sm:gap-[32px]">
                    {children}
                </div>
                <img src="/blog/decorations/dots.svg" alt="" className=" absolute top-[68%] translate-x-[50%] right-[76%] z-10" />
                <img src="/blog/decorations/rectangle-beige.svg" alt="" className="absolute top-[44%] translate-x-[50%] left-[-50%] sm:left-[10%] !z-10" />
                <img src="/blog/decorations/circle2.svg" alt="" className="absolute top-[72%] translate-x-[50%] right-[30%] z-10" />
                <img src="/blog/decorations/circle-mini2.svg" alt="" className="absolute top-[68%] translate-x-[50%] right-[50%] z-10" />
                <img src="/blog/decorations/dots.svg" alt="" className="absolute bottom-[-50px] translate-x-[50%] sm:right-[32%] z-10" />
            </section>
            {
                aplicar &&
                <div className="mt-[40px] sm:mt-[80px]">
                    <SectionAplicar data={data} />
                </div>
            }
            <section id="blog"
                className="mt-[48px] sm:mt-[60px] !z-20 relative">
                <SectionFinal data={data} />
            </section>
            <BtnToTop />
        </motion.div>
    )
}

export default BlogStructure
