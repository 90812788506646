interface Props {
  title: string;
  img: string;
  text: string;
  page: string;
}

const CardProyectos: React.FC<Props> = ({ title, img, text, page }) => {
  return (
    <div
      className=" w-full text-amarilloC 
      transition-all ease-in cursor-pointer hover:text-rosaC 
      pb-[28px] smAux:pb-[32px] smAux1:pb-[52px] mdAux2:pb-[40px] auxHero:pb-[56px] design:pb-0">
      <img alt={title}
        src={img}
        className='w-full rounded-[16px] mb-[16px]' />
      <a  
        href={page} target="_blank" rel="noreferrer"
        className="font-jost font-bold pb-[8px] 
        text-[16px] sm:text-[14px] xlAux:text-[20px] ">
        {title}
      </a>
      <p
        className="text-white font-sans text-[12px]">
        {text}
      </p>
    </div>
  )
}

export default CardProyectos
