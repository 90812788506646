import BlogStructure from "../../components/structure/BlogStructure"
import H3text from "../../components/structure/sections/H3text"
import Text from "../../components/structure/sections/Text"

interface Props {
    data: any
}
const Redefiniendo = ({ data }: Props) => {
    const pl = "sm:pl-[38px]"
    return (
        <BlogStructure data={data} maxW="max-w-[760px]"
            bio={data.redefiniendo.bio}
            title={data.redefiniendo.hero.title}
            hero={data.redefiniendo.galery.hero}
            althero={data.redefiniendo.galery.althero}
            text1={data.redefiniendo.hero.text1}
            text2={data.redefiniendo.hero.text2}>
            <H3text h3={data.redefiniendo.h3text1.title}
                text={data.redefiniendo.h3text1.text1} pl={pl} />
            <img alt={data.redefiniendo.galery.alt1}
                src={data.redefiniendo.galery.img1} />
            <H3text h3={data.redefiniendo.h3text2.title}
                text={data.redefiniendo.h3text2.text1} pl={pl} />
            <img alt={data.redefiniendo.galery.alt2}
                src={data.redefiniendo.galery.img2} />
            <H3text h3={data.redefiniendo.h3text3.title}
                text={data.redefiniendo.h3text3.text1} pl={pl} />
            <img alt={data.redefiniendo.galery.alt3}
                src={data.redefiniendo.galery.img3} />
            <H3text h3={data.redefiniendo.h3text4.title}
                text={data.redefiniendo.h3text4.text1} pl={pl} />
            <img alt={data.redefiniendo.galery.alt4}
                src={data.redefiniendo.galery.img4} />
            <H3text h3={data.redefiniendo.h3text5.title}
                text={data.redefiniendo.h3text5.text1} pl={pl} />
            <img alt={data.redefiniendo.galery.alt5}
                src={data.redefiniendo.galery.img5} />
            <H3text h3={data.redefiniendo.h3text6.title}
                text={data.redefiniendo.h3text6.text1} pl={pl} />
            <img alt={data.redefiniendo.galery.alt6}
                src={data.redefiniendo.galery.img6} />
            <Text text={data.redefiniendo.resumen} />
        </BlogStructure>
    )
}

export default Redefiniendo
