import { Link } from "react-router-dom"

interface Props {
    text: string
    link: string
}
const BtnLeermas = ({ text, link }: Props) => {
    return (
        <Link to={link}
            className="px-[36px] py-[12px] text-[12px] rounded-[60px]
            font-jost font-normal leading-4 
            bg-[#FF0062] text-white
            md:text-[20px] md:px-[78px] md:py-[20px] md:font-medium md:leading-7">
                {text}
        </Link>
    )


}

export default BtnLeermas