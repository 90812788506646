import BlogStructure from "../../components/structure/BlogStructure"
import H3text from "../../components/structure/sections/H3text"
import Img from "../../components/structure/sections/Img"
import Text from "../../components/structure/sections/Text"

interface Props {
   data: any
}
const Innovacion = ({ data }: Props) => {
   return (
      <BlogStructure data={data} maxW="max-w-[800px]" aplicar
         bio={data.innovacion.bio}
         title={data.innovacion.hero.title}
         hero={data.innovacion.galery.hero}
         althero={data.innovacion.galery.althero}
         text1={data.innovacion.hero.text1} >
         <img
            src={data.innovacion.galery.img1}
            alt={data.innovacion.galery.alt1} />
         <h2 className="h2blog"> {data.innovacion.casos.h2} </h2>
         <ol
            className=" grid gap-[16px] sm:gap-[32px] list-decimal
            font-jost font-bold 
            text-[16px] leading-[24px] pl-[20px]
            smAux:text-[22px] smAux:leading-[31px] smAux:pl-[28px] 
            sm:text-[24px] sm:leading-[32px] sm:pl-[30px]">
            <li>
               <H3text h3={data.innovacion.casos.h3text1.title}
                  text={data.innovacion.casos.h3text1.text} />
            </li>
            <Img
               img={data.innovacion.galery.img2}
               alt={data.innovacion.galery.alt2} />
            <li>
               <H3text h3={data.innovacion.casos.h3text2.title}
                  text={data.innovacion.casos.h3text2.text} />
            </li>
            <Img
               img={data.innovacion.galery.img3}
               alt={data.innovacion.galery.alt3} />
            <li>
               <H3text h3={data.innovacion.casos.h3text3.title}
                  text={data.innovacion.casos.h3text3.text} />
            </li>
            <Img
               img={data.innovacion.galery.img4}
               alt={data.innovacion.galery.alt4} />
         </ol>
         <h2 className="h2blog">{data.innovacion.lecciones.h2}</h2>
         <ol
            className=" grid gap-[16px] sm:gap-[32px] list-decimal
            font-jost font-bold 
            text-[16px] leading-[24px] pl-[20px]
            smAux:text-[22px] smAux:leading-[31px] smAux:pl-[28px] 
            sm:text-[24px] sm:leading-[32px] sm:pl-[30px]">
            <li>
               <H3text h3={data.innovacion.lecciones.h3text1.title}
                  text={data.innovacion.lecciones.h3text1.text} />
            </li>
            <li>
               <H3text h3={data.innovacion.casos.h3text2.title}
                  text={data.innovacion.lecciones.h3text2.text} />
            </li>
            <li>
               <H3text h3={data.innovacion.lecciones.h3text3.title}
                  text={data.innovacion.casos.h3text3.text} />
            </li>
            <li>
               <H3text h3={data.innovacion.lecciones.h3text3.title}
                  text={data.innovacion.casos.h3text3.text} />
            </li>
         </ol>
         <Text text={data.innovacion.resumen} />
      </BlogStructure>
   )
}

export default Innovacion
