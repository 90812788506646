import BlogStructure from "../../components/structure/BlogStructure"
import H32text from "../../components/structure/sections/H32text"
import Text from "../../components/structure/sections/Text"
// faltan text 2
interface Props {
   data: any
}
const Asesoramiento = ({ data }: Props) => {
   const pl = "sm:pl-[24px]"
   return (
      <BlogStructure data={data} maxW="max-w-[600px]"
         bio={data.asesoramiento.bio}
         title={data.asesoramiento.hero.title}
         hero={data.asesoramiento.galery.hero}
         althero={data.asesoramiento.galery.althero}
         text1={data.asesoramiento.hero.text1}
         text2={data.asesoramiento.hero.text2}>
         <H32text h3={data.asesoramiento.h3text1.title}
            text1={data.asesoramiento.h3text1.text1} pl={pl}
            text2={data.asesoramiento.h3text1.text2} />
         <img
            alt={data.asesoramiento.galery.alt1}
            src={data.asesoramiento.galery.img1} />
         <H32text h3={data.asesoramiento.h3text2.title}
            text1={data.asesoramiento.h3text2.text1} pl={pl}
            text2={data.asesoramiento.h3text2.text2} />
         <img
            alt={data.asesoramiento.galery.alt2}
            src={data.asesoramiento.galery.img2} />
         <H32text h3={data.asesoramiento.h3text3.title}
            text1={data.asesoramiento.h3text3.text1} pl={pl}
            text2={data.asesoramiento.h3text3.text2} />
         <img
            alt={data.asesoramiento.galery.alt3}
            src={data.asesoramiento.galery.img3} />
         <H32text h3={data.asesoramiento.h3text4.title}
            text1={data.asesoramiento.h3text4.text1} pl={pl}
            text2={data.asesoramiento.h3text4.text2} />
         <img
            alt={data.asesoramiento.galery.alt4}
            src={data.asesoramiento.galery.img4} />
         <Text text={data.asesoramiento.resumen} />
      </BlogStructure>
   )
}

export default Asesoramiento
