interface Props {
  text: string
}
const BtnContáctanos = ({text}: Props) => {
  return (
    <button 
      className=' bg-amarilloC h-[40px] transition-all ease-in
      flex items-center justify-center justify-items-center
      w-[120px] rounded-[60px] gap-[8px]
      smAux:w-[132px] mdAux:w-[173px] mdAux:rounded-[20px] mdAux:gap-[9px]
      hover:bg-transparent hover:border-negroC hover:border-[2px]'>
      <img alt=''
        src='/svg/escribenos.svg'
        className='w-[12px] smAux:w-[14px] mdAux:w-[16px]'>
      </img>
      <p
        className='font-jost font-medium text-negroC flex items-center
        text-[12px] smAux:text-[16px] 
        mdAux:font-[Poppins] mdAux:font-bold mdAux:text-[14px] lg:text-[16px]'>
        {text}
      </p>
    </button>
  )
}

export default BtnContáctanos