
interface Props {
  text: string;
  img: string;
  name: string;
  de: string;
}

const CardComentarios = ({ text, img, name, de }: Props) => {
  return (
    <div
      className="grid mx-auto
      w-full mdAux2:w-[519px] xlAux2:w-full
      grid-cols-[50px_1fr] gap-[16px]
      smAux1:grid-cols-[56px_1fr] smAux1:gap-[24px]
      mdAux2:grid-cols-[150px_1fr]  xlAux:gap-[32px]
      mb-[32px] smAux:mb-[58px] mdAux2:mb-[40px] xlAux2:mb-[110px]">
      <img alt={name}
        src={img}
        className='w-full shadow-xl rounded-full' />
      <div>
        <p
          className="font-jost font-bold text-left
              text-[18px] xlAux:text-[24px]">
          {name}
        </p>
        <p
          className="font-nunito text-[12px] uppercase
               text-negroC pb-[8px]">
          {de}
        </p>
        <p className="font-nunito text-left 
              text-[14px] sm:text-[16px]">
          {text}
        </p>
      </div>
    </div>
  )
}

export default CardComentarios