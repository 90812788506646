import React from 'react'


interface HoverState {
    fillBg: string;
    fillIcon: string;
}

const Facebook = () => {
    const [hover, setHover] = React.useState<HoverState>({
        fillBg: '#fff',
        fillIcon: '#3A3A3A',
    })

    return (
        <svg
            width={36}
            height={36}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className='cursor-pointer'
            onMouseEnter={() => {
                setHover({
                    fillBg: '#FF0062',
                    fillIcon: '#FFF',
                })
            }}
            onMouseLeave={() => {
                setHover({
                    fillBg: '#fff',
                    fillIcon: '#3A3A3A',
                })
            }
        }
        >
            <g clipPath="url(#a)">
                <path
                    d="M18 36c9.941 0 18-8.059 18-18S27.941 0 18 0 0 8.059 0 18s8.059 18 18 18Z"
                    fill={hover.fillBg}
                />
                <path
                    d="M22.525 18.705h-3.212v11.767h-4.866V18.704h-2.314v-4.136h2.314v-2.676c0-1.913.91-4.91 4.91-4.91l3.604.015v4.014h-2.615c-.43 0-1.032.214-1.032 1.127v2.434h3.636l-.425 4.132Z"
                    fill={hover.fillIcon}
                />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M0 0h36v36H0z" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default Facebook