import { useMediaQuery } from 'react-responsive'
import BtnEscribenos from '../components/buttons/BtnEscribenos'
import ComentariosSwiper from '../components/swipers/ComentariosSwiper.js'
import ComoAyudarteCard from '../components/cards/ComoAyudarteCard'
import ProyectosSwiper from '../components/swipers/ProyectosSwiper'
import CardProyectos from '../components/cards/CardProyectos'
import { motion } from "framer-motion"
import { CardEquipo } from '../components/cards/CardEquipo'
import EquipoSwiper from '../components/swipers/EquipoSwiper'
import SectionFinal from '../components/structure/SectionFinal'
type Props = {
   data: any
};

const PageHome = ({ data }: Props) => {

   const isMd = useMediaQuery({ query: '(max-width: 768px)' })
   const isLg = useMediaQuery({ query: '(max-width: 860px)' })
   const isXl = useMediaQuery({ query: '(max-width: 1440px)' })

   return (
      <motion.div
         initial={{ opacity: 0 }}
         animate={{ opacity: 1 }}
         transition={{ duration: 1.5 }}
         className='overflow-x-hidden'>
         <section id="hero"
            className='relative
            md:h-[360px] mdAux2:h-[446px] lg:h-[468px] xl:h-[528px] '>
            <div
               className='containerW-1200 h-full grid justify-center  md:items-center relative
               md:grid-cols-[1.2fr_1fr] lg:grid-cols-[1.5fr_0.5fr]'>
               <div
                  className='max-w-[460px] grid justify-items-center mt-[36px] relative
                  mdAux2:w-[320px] mdAux:w-[342px] auxHero:w-[376px]
                  mdAux2:justify-items-start md:mt-0 lgAux:w-[472px] xl:max-w-[554px] xl:w-[554px]'>
                  <h1 className='md:pt-[16px] lg:pt-0'>{data.section1.title}</h1>
                  <p className="p1 pt-[16px] pb-[32px] smAux:pt-24px] smAux:pb-[32px] 
                  mdAux2:pb-[24px] mdAux2:pt-[36px] lg:py-[32px]
                  leading-[24px] lg:leading-[32px]">
                     {data.section1.text}
                  </p>
                  <a target="_blank" rel="noreferrer"
                     href="https://api.whatsapp.com/send?phone=51933509472&text=Hola,%20quiero%20m%C3%A1s%20informaci%C3%B3n%20sobre%20los%20productos%20y%20servicios%20de%20Ciclos%20Studio">
                     <BtnEscribenos text={data.section1.btn} />
                  </a>
                  <img alt=''
                     src='svg/decorations/bigCircle-hero.svg'
                     className='absolute -z-10
                     top-[50%] translate-y-[30%] right-[-10%]
                     invisible xl:visible'>
                  </img>
               </div>
               <div className='design:hidden'></div>
               {
                  !isMd &&
                  <img
                     className=' right-0  max-w-[706px] self-start top-0 lg:rounded-bl-[90px] rounded-bl-[80px]
                     md:w-[40vw] md:absolute mdAux2:w-[48vw] mdAux:w-[48vw] lg:w-[46vw] auxHero2:w-[40vw] design:w-[46vw] mx-auto 
                     design:relative design:translate-x-[50px]
                     object-cover h-full w-full'
                     alt='Hero'
                     src='/home/hero.jpg'
                  />
               }
               <img alt=''
                  src='svg/decorations/points-ayudarte.svg'
                  className='absolute -z-10
                  bottom-[-12px] lg:bottom-[20px] left-[-10%]
                  invisible  md:visible'>
               </img>
               <img alt=''
                  src='svg/decorations/smallCircle-hero.svg'
                  className='absolute -z-10
                  top-[80%] left-[25%]
                  invisible  xl:visible'>
               </img>
            </div>
            {
               isMd &&
               <img
                  className='w-[100vw] mt-[32px] rounded-bl-[40px] smAux:rounded-bl-[70px]'
                  alt='Hero'
                  src='/home/hero.jpg'
               />
            }
         </section>

         <section id="clientes-aliados"
            className='pt-[48px] mdAux2:pt-[64px] lg:pt-[80px]
            pb-[24px] smAux:pb-[48px] mdAux2:pb-0'>
            <div
               className='containerW-1200 grid items-center
               gap-[22px] smAux:gap-[32px] mdAux2:gap-[24px]
               auxClients:grid-cols-[327px_1fr_634px] auxClients:gap-0'>
               <div
                  className='grid items-center 
                  auxClients:justify-start '>
                  <h2 className='text-center auxClients:text-left'>
                     {data.section2.title}
                  </h2>
                  <p className='px-2 max-w-[336px]  p1 text-center
                  sm:px-0 md:max-w-[483px] mx-auto
                  auxClients:max-w-[336px] auxClients:text-left mdAux2:leading-[32px]
                  mt-[20px] smAux:mt-[32px] lg:mt-[20px] design:mt-[24px]'>
                     {data.section2.text}
                  </p>
               </div>
               <div className='invisible auxClients:visible' />
               <div
                  className='grid justify-items-center 
                  gap-x-[40px] gap-y-[64px] place-items-center        
                  sm:px-[3%] sm:gap-x-[30px] sm:grid-cols-3 sm:gap-y-[26px] auxClients:px-0'>
                  <img src="svg/clientes-aliados/vertices.svg" alt="Vértices" />
                  <img src="svg/clientes-aliados/gloria.svg" alt="Gloria" />
                  <img src="svg/clientes-aliados/san-marcos.svg" alt="San Marcos" />
                  <img src="svg/clientes-aliados/caja-sullana.svg" alt="Caja Sullana" />
                  <img src="svg/clientes-aliados/lyon.svg" alt="Lyon" />
                  <img src="svg/clientes-aliados/nextDerma.svg" alt="My NextDerma" />
                  <img src="svg/clientes-aliados/uniflex.svg" alt="Uniflex" />
                  <img src="svg/clientes-aliados/cbc.svg" alt="Corredor Biológico en el Caribe" />
                  <img src="svg/clientes-aliados/coloriza.svg" alt="Coloriza" />
               </div>
            </div>
         </section>

         <section id="servicios"
            className='py-[24px] smAux:pb-[48px] smAux:pt-[54px] overflow-hidden
            mdAux2:pb-[40px] mdAux2:pt-[64px]
            xl:pb-[72px] xl:pt-[80px]'>
            <div className='containerW-1200  xl:flex xl:items-center relative'>
               <div
                  className='mx-auto text-center max-w-[490px] relative
                  xl:text-left xl:mx-0
                  xl:max-w-[384px]'>
                  <h2>
                     {data.section3.title}
                  </h2>
                  <p
                     className="p1 mt-[16px] leading-[24px]
                     mdAux2:mt-[32px] lg:mt-[24]
                     xl:leading-[32px]">
                     {data.section3.text}
                  </p>
                  <img alt=''
                     src='svg/decorations/circle-investigation.svg'
                     className='absolute -z-10
                     top-[-80%] left-[-20%] 
                     invisible xl:visible'>
                  </img>
                  <img alt=''
                     src='svg/decorations/points-ayudarte.svg'
                     className='absolute -z-10 
                     bottom-[-62%] left-0
                     invisible xl:visible'>
                  </img>
               </div>
               <div
                  className='grid  mt-[30px] justify-items-center
                  mdAux2:mx-[8%] mdAux2:grid-cols-2
                  xl:ml-auto  xl:justify-end xl:gap-[30px] xl:mt-0 xl:mx-0'>
                  <div className='grid mdAux2:mt-[69px] gap-[24px]'>
                     <ComoAyudarteCard
                        imgUrl="/home/servicios/card-ayuda-2.svg"
                        title={data.section3.card2.title}
                        paragraph={data.section3.card2.text}
                     />
                     <ComoAyudarteCard
                        imgUrl="/home/servicios/card-ayuda-4.svg"
                        title={data.section3.card4.title}
                        paragraph={data.section3.card4.text}
                     />
                  </div>
                  <div className='flex flex-col gap-[24px] mt-[24px] mdAux2:mt-[16px]'>
                     <ComoAyudarteCard
                        imgUrl="/home/servicios/card-ayuda-1.svg"
                        title={data.section3.card1.title}
                        paragraph={data.section3.card1.text}
                     />
                     <ComoAyudarteCard
                        imgUrl="/home/servicios/card-ayuda-3.svg"
                        title={data.section3.card3.title}
                        paragraph={data.section3.card3.text}
                     />
                  </div>
               </div>
               <img alt=''
                  src='svg/decorations/rectangle-ayudarte.svg'
                  className='absolute top-0 -z-10 w-full h-max-[619px]
                  right-0 translate-y-[75%] auxHero:translate-y-[300px]
                  xl:translate-y-[-50%] xl:top-[50%] xl:translate-x-[120px] 
                  xl:w-[1043px]
                  invisible mdAux2:visible'>
               </img>
            </div>
         </section>

         <section id="proyectos-realizados"
            className='bg-negroC'>
            <div className="container pl-0
               py-[48px] mdAux2:pt-[64px] design:py-[72px]">
               <h2
                  className='text-white text-center mx-auto
                  max-w-[300px] sm:max-w-none
                  pb-[24px] smAux2:pb-[42px] mdAux2:pb-[52px]'>
                  {data.section4.title}
               </h2>
               {isXl ?
                  <ProyectosSwiper data={data} />
                  :
                  <div className='grid grid-cols-3 gap-x-[56px] gap-y-[40px]'>
                     <CardProyectos title={data.section4.card1.title} text={data.section4.card1.subtitle}
                        img="home/proyectos/naming-design.png"
                        page='https://naming.design/' />
                     <CardProyectos title={data.section4.card2.title} text={data.section4.card2.subtitle}
                        img="home/proyectos/congreso-icbar.png"
                        page='http://congresoicbar.com/' />
                     <CardProyectos title={data.section4.card3.title} text={data.section4.card3.subtitle}
                        img="home/proyectos/next-derma.png"
                        page='https://play.google.com/store/apps/details?id=com.mynextderma.app&hl=es_MX' />
                     <CardProyectos title={data.section4.card4.title} text={data.section4.card4.subtitle}
                        img="home/proyectos/cbc.png"
                        page='https://virtualexpo.cbcbio.org/' />
                     <CardProyectos title={data.section4.card5.title} text={data.section4.card5.subtitle}
                        img="home/proyectos/anc.png"
                        page='https://ancperu.org/ancperu/' />
                     <CardProyectos title={data.section4.card6.title} text={data.section4.card6.subtitle}
                        img="home/proyectos/indusmart.png"
                        page='https://indusmart.com.ar/' />
                     <CardProyectos title={data.section4.card7.title} text={data.section4.card7.subtitle}
                        img="home/proyectos/vertices.png"
                        page='https://vertices.pro/' />
                     <CardProyectos title={data.section4.card8.title} text={data.section4.card8.subtitle}
                        img="home/proyectos/world-boosting.png"
                        page='https://worldboosting.com/' />
                     <CardProyectos title={data.section4.card9.title} text={data.section4.card9.subtitle}
                        img="home/proyectos/bromovil.png"
                        page='https://bromovil.com/' />
                  </div>}
            </div>
         </section>

         <section id="comentarios">
            <div className='container relative
               py-[40px] design:py-[72px]'>
               <p
                  className='font-jost font-light smAux2:font-bold
                  text-[24px] smAux2:text-[26px] mdAux2::text-[28px] design:text-[32px]
                  pb-[24px] mdAux1:pb-[54px] design:pb-[56px]
                  text-center smAux2:text-left mdAux1:text-center design:text-left'>
                  Algunos comentarios
               </p>
               <div>
                  <ComentariosSwiper data={data} />
               </div>
               <img alt=''
                  src='svg/decorations/circle-investigation.svg'
                  className='absolute  -z-10
                  top-[0%] left-[100px] design:top-[38%] design:left-[45%] 
                  invisible xl:visible'>
               </img>
               <img alt=''
                  src='svg/decorations/rectangle-investigacion.svg'
                  className='absolute top-0 left-0 -z-10 opacity-30
                  lg:translate-x-[-128px]
                  h-[350px] w-[538px] xl:h-[380px]
                  design::h-[410px] xl:w-[628px]
                  invisible auxHero:visible'>
               </img>
            </div>
         </section>

         <section id="equipo">
            <div className='container !max-w-[1000px] relative'>
               <h2 className='text-center'>{data.section6.title}</h2>
               <p
                  className='p1 text-center mt-[16px] sm:mt-[8px] lg:mt-[24px]'>
                  {data.section6.text}
               </p>
               {
                  isLg ?
                     <EquipoSwiper />
                     :
                     <div
                        className='grid grid-cols-3 mx-auto
                        sm:gap-x-[73px] sm:gap-y-[52px] sm:pt-[72px]'>
                        <CardEquipo name='Marialejandra Arrilucea' cargo='Product Designer' foto='home/equipo/mariale-arrilucea.png' />
                        <CardEquipo name='Allison de la Cruz' cargo='Product Designer' foto='home/equipo/allison-delaCruz.png' />
                        <CardEquipo name='Gonzalo Reyes' cargo='UX Project Manager' foto='home/equipo/gonzalo-reyes.png' />
                        <CardEquipo name='Robert Quillas' cargo='Technical Leader' foto='home/equipo/robert-quillas.png' />
                        <CardEquipo name='Heison Espinoza' cargo='Founder & PM' foto='home/equipo/heison-espinoza.png' />
                        <CardEquipo name='Natalia Diaz' cargo='Frontend' foto='home/equipo/natalia-diaz.png' />
                        <CardEquipo name='Diego bazan' cargo='UX/UI Designer' foto='home/equipo/diego-bazan.png' />
                        <CardEquipo name='Brigette Sosa' cargo='UI Designer' foto='home/equipo/brigitte-sosa.png' />
                        <CardEquipo name='Gema Berrocal' cargo='UI Designer' foto='home/equipo/gema-berrocal.png' />
                     </div>
               }
               <img alt=''
                  src='svg/decorations/points-ayudarte.svg'
                  className='absolute -z-10 rotate-90
                  top-[75%] left-[-34%]
                  invisible xl:visible'>
               </img>
            </div>
         </section>

         <section id="ciclos-canvas">
            <div className='container py-[48px] lg:py-[86px]'>
               <h2 className='text-center md:text-[36px]'>{data.section7.title}</h2>
               <p
                  className='p1 text-center my-[16px] w-full mx-auto
                  sm:mt-[8px] sm:max-w-[618px]
                  lg:mt-[24px] lg:mb-[32px]'>
                  {data.section7.text}
               </p>
               <img src={data.section7.img} alt='Ciclos Canvas'
                  className='mx-auto' />
            </div>
         </section>

         <section id='blog'
            className='pb-[48px] sm:pb-[86px]'>
            <SectionFinal data={data} />
         </section>
      </motion.div >    
   )
}

export default PageHome