import React from 'react'


interface HoverState {
    fillBg: string;
    fillIcon: string;
}
const Instagram = () => {
    const [hover, setHover] = React.useState<HoverState>({
        fillBg: '#fff',
        fillIcon: '#3A3A3A',
    })
    return (
        <svg
            width={36}
            height={36}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className='cursor-pointer'
            onMouseEnter={() => {
                setHover({
                    fillBg: '#FF0062',
                    fillIcon: '#FFF',
                })
            }}
            onMouseLeave={() => {
                setHover({
                    fillBg: '#fff',
                    fillIcon: '#3A3A3A',
                })
            }
            }
        >
            <g clipPath="url(#a)">
                <path
                    d="M18 36c9.94 0 18-8.06 18-18 0-9.941-8.06-18-18-18C8.059 0 0 8.059 0 18c0 9.94 8.059 18 18 18Z"
                    fill={hover.fillBg}
                />
                <path
                    d="M22.152 8h-8.147A6.011 6.011 0 0 0 8 14.005v8.147a6.012 6.012 0 0 0 6.005 6.004h8.147a6.012 6.012 0 0 0 6.005-6.004v-8.147A6.011 6.011 0 0 0 22.152 8Zm3.977 14.152a3.977 3.977 0 0 1-3.977 3.977h-8.147a3.977 3.977 0 0 1-3.977-3.977v-8.147a3.977 3.977 0 0 1 3.977-3.977h8.147a3.977 3.977 0 0 1 3.977 3.977v8.147Z"
                    fill={hover.fillIcon}
                />
                <path
                    d="M18.078 12.865a5.22 5.22 0 0 0-5.213 5.213 5.22 5.22 0 0 0 5.213 5.213 5.219 5.219 0 0 0 5.213-5.213 5.219 5.219 0 0 0-5.213-5.213Zm0 8.398a3.185 3.185 0 1 1 0-6.37 3.185 3.185 0 0 1 0 6.37Zm6.47-8.359a1.25 1.25 0 1 1-2.498 0 1.25 1.25 0 0 1 2.498 0Z"
                    fill={hover.fillIcon}
                />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M0 0h36v36H0z" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default Instagram