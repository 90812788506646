interface Props {
  name: string;
  foto: string;
  cargo: string;
}
export const CardEquipo: React.FC<Props> = ({ name, foto, cargo }) => {
  return (
    <div className="w-auto h-[100%]">
      <img
        alt={name}
        src={foto}
        className="w-full rounded-full mx-auto
        max-w-[240px] sm:max-w-none"/>
      <h4
        className="pt-[12px] sm:pt-[24px] text-center">
        {name}
      </h4>
      <p
        className="font-jost font-light text-center pt-[4px]
        text-[12px] md:text-[14px]">
        {cargo}
      </p>
    </div>
  )
}
