import BlogStructure from "../../components/structure/BlogStructure"
import H3text from "../../components/structure/sections/H3text"
import Text from "../../components/structure/sections/Text"

interface Props {
    data: any
}
const Impulsar = ({ data }: Props) => {
    return (
        <BlogStructure data={data} maxW="max-w-[650px]" aplicar
            bio={data.impulsar.bio}
            title={data.impulsar.hero.title}
            hero={data.impulsar.galery.hero}
            althero={data.impulsar.galery.althero}
            text1={data.impulsar.hero.text1} >
            <img
                src={data.impulsar.galery.img1}
                alt={data.impulsar.galery.alt1} />
            <ol
                className=" grid gap-[16px] sm:gap-[32px] list-decimal
            font-jost font-bold 
            text-[16px] leading-[24px] pl-[20px]
            smAux:text-[22px] smAux:leading-[31px] smAux:pl-[28px] 
            sm:text-[24px] sm:leading-[32px] sm:pl-[30px]">
                <li>
                    <H3text h3={data.impulsar.h3text1.title}
                        text={data.impulsar.h3text1.text} />
                </li>
                <img
                    src={data.impulsar.galery.img2}
                    alt={data.impulsar.galery.alt2} />
                <li>
                    <H3text h3={data.impulsar.h3text2.title}
                        text={data.impulsar.h3text2.text} />
                </li>
                <img
                    src={data.impulsar.galery.img3}
                    alt={data.impulsar.galery.alt3} />
                <li>
                    <H3text h3={data.impulsar.h3text3.title}
                        text={data.impulsar.h3text3.text} />
                </li>
                <img
                    src={data.impulsar.galery.img4}
                    alt={data.impulsar.galery.alt4} />
                <li>
                    <H3text h3={data.impulsar.h3text4.title}
                        text={data.impulsar.h3text4.text} />
                </li>
                <img
                    src={data.impulsar.galery.img5}
                    alt={data.impulsar.galery.alt5} />
                <li>
                    <H3text h3={data.impulsar.h3text5.title}
                        text={data.impulsar.h3text5.text} />
                </li>
                <img
                    src={data.impulsar.galery.img6}
                    alt={data.impulsar.galery.alt6} />
                <li>
                    <H3text h3={data.impulsar.h3text6.title}
                        text={data.impulsar.h3text6.text} />
                </li>
                <img
                    src={data.impulsar.galery.img7}
                    alt={data.impulsar.galery.alt7} />
                <li>
                    <H3text h3={data.impulsar.h3text7.title}
                        text={data.impulsar.h3text7.text} />
                </li>
                <img
                    src={data.impulsar.galery.img8}
                    alt={data.impulsar.galery.alt8} />
                <li>
                    <H3text h3={data.impulsar.h3text8.title}
                        text={data.impulsar.h3text8.text} />
                </li>
                <img
                    src={data.impulsar.galery.img9}
                    alt={data.impulsar.galery.alt9} />
            </ol>
            <Text text={data.impulsar.resumen} />
        </BlogStructure>
    )
}

export default Impulsar
