import { useMediaQuery } from "react-responsive";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion"

interface Props {
   text: string;
   img: string;
   title: string;
   link: string;
   theme1: string;
   theme2: string;
}
const CardPageBlog = ({ text, img, title, link, theme1, theme2 }: Props) => {
   const isSm = useMediaQuery({ query: '(min-width: 840px)' })
   const isLg = useMediaQuery({ query: '(min-width: 1280px)' })
   const limitText = text.length >  (isSm ? 300 : 150) ? (isSm ? `${text.slice(0, 400)}...` : `${text.slice(0, 150)}...`) : text;
   return (
      <motion.div
         initial={{ opacity: 0 }}
         animate={{ opacity: 1 }}
         transition={{ duration: 1 }}
      >
         <NavLink to={link}
            className="rounded-2xl border border-solid border-[#C5C5C5]  shadow-sm
         grid gap-[16px] p-[16px] !z-20 bg-white transition-all ease-in-out hover:bg-[#F7F5E9]
         sm:grid-cols-[200px_1fr]
         md:grid-cols-[300px_1fr] md:gap-[24px] md:p-[24px] 
         lg:grid-cols-[0.8_1fr] lg:h-[300px] 
         xl:grid-cols-[500px_1fr] xl:p-[32px] xl:h-[328px]">
            <img className="object-cover rounded-lg
               h-[155px] sm:!h-full w-full"
               src={img}
               alt={title}
            />

            <div className="flex flex-col gap-[8px] sm:gap-[15px]">
               <h3
                  className=" text-[16px] leading-[22px] 
               sm:text-[20px]  sm:leading-[28px]
               lg:text-[24px] lg:leading-[32px]">
                  {title}
               </h3>
               <p
                  className=" font-sans text-[12px] leading-[130%] 
               sm:text-[14px] sm:leading-[22px]">
                  {isLg ? <span>{text}</span> : <span>{limitText}</span>}
               </p>
               <div className="flex gap-[16px] text-[12px]  text-[#FF0062] ">
                  <p className="font-sans !font-[700]">{theme1}</p>
                  <p className="font-sans !font-[700]">{theme2}</p>
               </div>
            </div>
         </NavLink>
      </motion.div>
   )
}

export default CardPageBlog