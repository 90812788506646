import { NavLink } from "react-router-dom";

interface Props {
    text: string;
    img: string;
    title: string;
    link: string
}

const CardBlog: React.FC<Props> = ({ text, img, title, link }) => {
    return (
        <NavLink to={"/blog/" + link}>
            <div
                className="relative mx-auto  border-[1px] border-[#C5C5C5]/50 rounded-[14px]
            max-w-[220px] p-[7.5px] !h-full
            smAux:max-w-[240px] smAux:p-[8px]
            smAux1:max-w-[336px] smAux1:px-[16px] smAux1:py-[16px]
            lg:max-w-[384px] lg:w-full lg:px-[16px] lg:py-[10px]
            mb-[44px] smAux:mb-[48px] mdAux2:mb-[64px] lgAux:mb-[50px]
            hover:bg-[#F7F5E9] transition-all ease-in cursor-pointer">
                <img alt={title}
                    src={img}
                    className="object-cover w-full rounded-[8px]
                    h-[132px] md:h-[150px] lg:h-[185px]">
                </img>
                <h5
                    className="font-jost font-bold 
                mt-[16px] text-[12px] leading-[18px]
                smAux:text-[14px] smAux:leading-[20px]
                smAux1:mt-[8px] mdAux2:text-[16px] smAux1:leading-[22px]
                lg:mt-[13px]">
                    {title}
                </h5>
                <p
                    className="font-sans text-[#FF0062] font-bold
                mt-[6px] text-[12px] leading-[26px]
                smAux1:text-[11px] smAux1:mt-[10px] smAux1:leading-[15px]">
                    {text}
                </p>
            </div>
        </NavLink>
    )
}

export default CardBlog