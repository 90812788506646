interface Props {
  text: string;
}
const Text = ({ text }: Props) => {
  return (
    <div className="grid gap-[20px]">
      <div className="w-full h-[1px] bg-[#BCBCBC]"/>
      <p className="text-[14px] leading-[20px] 
        sm:text-[20px] sm:leading-[36px]">
        {text}
      </p>
      <div className="w-full h-[1px] bg-[#BCBCBC]"/>
    </div>
  )
}

export default Text
