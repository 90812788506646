import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper";
import CardProyectos from "../cards/CardProyectos";

export default function ProyectosSwiper({ data }) {
  return (
    <>
      <Swiper
        style={{
          "--swiper-pagination-color": "#FFD600",
          "--swiper-pagination-bullet-inactive-color": "#E7F0FC",
          "--swiper-pagination-bullet-inactive-opacity": "1",
          ".swiper-pagination-bullet": "swiper-pagination-bullet"
        }}
        slidesPerView={3}
        spaceBetween={24}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          1286: {
            slidesPerView: 3,
          },
          834: {
            slidesPerView: 2.3,
            spaceBetween: 24
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 24
          },
          360: {
            slidesPerView: 1.3,
            spaceBetween: 40
          },
          200: {
            slidesPerView: 1.2,
            spaceBetween: 24
          },
        }}
        modules={[Pagination]}
        className="mySwiper"
      >

        <SwiperSlide>
          <CardProyectos title={data.section4.card1.title} text={data.section4.card1.subtitle}
            img="home/proyectos/naming-design.png"
            page='https://naming.design/' />
        </SwiperSlide>
        <SwiperSlide>
          <CardProyectos title={data.section4.card2.title} text={data.section4.card2.subtitle}
            img="home/proyectos/congreso-icbar.png"
            page='http://congresoicbar.com/' />
        </SwiperSlide>
        <SwiperSlide>
          <CardProyectos title={data.section4.card3.title} text={data.section4.card3.subtitle}
            img="home/proyectos/next-derma.png"
            page='https://play.google.com/store/apps/details?id=com.mynextderma.app&hl=es_MX' />
        </SwiperSlide>
        <SwiperSlide>
          <CardProyectos title={data.section4.card4.title} text={data.section4.card4.subtitle}
            img="home/proyectos/cbc.png"
            page='https://virtualexpo.cbcbio.org/' />
        </SwiperSlide>
        <SwiperSlide>
          <CardProyectos title={data.section4.card5.title} text={data.section4.card5.subtitle}
            img="home/proyectos/anc.png"
            page='https://ancperu.org/ancperu/' />
        </SwiperSlide>
        <SwiperSlide>
          <CardProyectos title={data.section4.card6.title} text={data.section4.card6.subtitle}
            img="home/proyectos/indusmart.png"
            page='https://indusmart.com.ar/' />
        </SwiperSlide>
        <SwiperSlide>
          <CardProyectos title={data.section4.card7.title} text={data.section4.card7.subtitle}
            img="home/proyectos/vertices.png"
            page='https://vertices.pro/' />
        </SwiperSlide>
        <SwiperSlide>
          <CardProyectos title={data.section4.card8.title} text={data.section4.card8.subtitle}
            img="home/proyectos/world-boosting.png"
            page='https://worldboosting.com/' />
        </SwiperSlide>
        <SwiperSlide>
          <CardProyectos title={data.section4.card9.title} text={data.section4.card9.subtitle}
            img="home/proyectos/bromovil.png"
            page='https://bromovil.com/' />
        </SwiperSlide>
      </Swiper>
    </>
  );
}
