import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import CardComentarios from "../cards/CardComentarios";

export default function ComentariosSwiper({ data }) {
  return (
    <>
      <Swiper
        style={{
          paddingBottom: "50px !important",
          "--swiper-pagination-color": "#FF0062",
          "--swiper-pagination-bullet-inactive-color": "#E7F0FC",
          "--swiper-pagination-bullet-inactive-opacity": "1",
          "--swiper-pagination-bullet-vertical-gap": "50px",
          ".swiper-pagination-bullet": "swiper-pagination-bullet"
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        breakpoints={{
          1200: {
            maxWidth: "1174px",
            slidesPerView: 2,
            slidesPerGroup: 1,
            spaceBetween: 120,
          },
          834: {
            maxWidth: "519px",
            slidesPerView: 1,
            slidesPerGroup: 1,
            spaceBetween: 32,
          },
          360: {
            maxWidth: "320px",
            slidesPerView: 1.3,
            slidesPerGroup: 1,
            spaceBetween: 16,
          },
          260: {
            maxWidth: "239px",
            slidesPerView: 1.2,
            slidesPerGroup: 1,
            spaceBetween: 16,
          },
        }}
        className="mySwiper"
      >
        <SwiperSlide>
          <CardComentarios name={data.section5.card1.title} de={data.section5.card1.subtitle}
            img='/home/comentarios/ciclos-pink.png'
            text={data.section5.card1.text} />
        </SwiperSlide>
        <SwiperSlide>
          <CardComentarios name={data.section5.card2.title} de={data.section5.card2.subtitle}
            img='/home/comentarios/ciclos-yellow.png'
            text={data.section5.card2.text} />
        </SwiperSlide>
        <SwiperSlide>
          <CardComentarios name={data.section5.card3.title} de={data.section5.card3.subtitle}
            img='/home/comentarios/ciclos-black.png'
            text={data.section5.card3.text} />
        </SwiperSlide>
        <SwiperSlide>
          <CardComentarios name={data.section5.card4.title} de={data.section5.card4.subtitle}
            img='/home/comentarios/ciclos-cyan.png'
            text={data.section5.card4.text} />
        </SwiperSlide>
        <SwiperSlide>
          <CardComentarios name={data.section5.card5.title} de={data.section5.card5.subtitle}
            img='/home/comentarios/ciclos-white.png'
            text={data.section5.card5.text}/>
        </SwiperSlide>
      </Swiper>
    </>
  );
} 