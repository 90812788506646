import { Routes, Route } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { useState, useEffect } from 'react';
import PageHome from './pages/PageHome';
import Footer from './shared/Footer';
import Splash from './components/animations/Splash';
import ScrollToTop from './hooks/ScrollToTop';
import BlogRoutes from './routes/BlogRoutes';
import PageBlog from './pages/PageBlog';
import EstrategiasEfectivas from './pages/blog/EstrategiasEfectivas';
import GlosarioUX from './pages/blog/GlosarioUX';
import Innovacion from './pages/blog/Innovacion';
import PrincipalRoute from './routes/PrincipalRoute';
import Impulsar from './pages/blog/Impulsar';
import Importancia from './pages/blog/Importancia';
import Asesoramiento from './pages/blog/Asesoramiento';
import Redefiniendo from './pages/blog/Redefiniendo';
import Futuro from './pages/blog/Futuro';

function App() {
  const dataIngles = require('./bd/en/Home.json');
  const dataSpanish = require('./bd/es/Home.json');
  const [splash, setSplash] = useState(false);
  const [data, setData] = useState(dataSpanish);

  useEffect(() => {
    setTimeout(() => {
      setSplash(false);
    }, 3500);
  }, []);

  const changeLanguaje = () => {
    if (data === dataSpanish) {
      setData(dataIngles);
    } else {
      setData(dataSpanish);
    }
  };

  return (
    <AnimatePresence>
      {splash ? (
        <Splash key="splash" />
      ) : (
        <motion.div
          className="App h-full overflow-y-auto overflow-x-hidden pt-[50px] smAux1:pt-[64px] auxDesktopNav:pt-[72px]"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1.5 }}
        >
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<PrincipalRoute data={data} changeLanguaje={changeLanguaje} />}>
              <Route index element={<PageHome data={data} />} />
            </Route>

            <Route path="blog" element={<BlogRoutes data={data} changeLanguaje={changeLanguaje} />}>
              <Route index element={<PageBlog data={data} />} />
              <Route path="advertising-strategies-increase-sales" element={<EstrategiasEfectivas data={data} />} />
              <Route path="glossary-terminology-ux-ui-design" element={<GlosarioUX data={data} />} />
              <Route path="success-cases-netflix-zoom-tesla" element={<Innovacion data={data} />} />
              <Route path="strategies-boost-grow-online-business" element={<Impulsar data={data} />} />
              <Route path="user-experience-importance-ux-ui" element={<Importancia data={data} />} />
              <Route path="digital-consulting-risks-strategy" element={<Asesoramiento data={data} />} />
              <Route path="prototips-online-education-ux-ui" element={<Redefiniendo data={data} />} />
              <Route path="ux-design-trends-xr-ai-multi-device" element={<Futuro data={data} />} />
            </Route>
          </Routes>
          <Footer data={data} />
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default App;
