import BtnEscribenos2 from "../buttons/BtnEscribenos2"

interface Props {
    data: any
}

const SectionAplicar = ({ data }: Props) => {
    return (
        <section className='bg-[#FFE8F1] py-[22px] md:py-[44px]'>
            <div className='w-[86%] !mx-auto !max-w-[810px]'>
                <div>
                    <h2 className="font-sans font-normal text-[24px] text-center
                        md:text-[40px]">
                        {data.sectionAplicar.h2}
                    </h2>
                    <div className="grid justify-center gap-[16px] items-center
                        sm:flex sm:justify-between 
                    pt-[16px] md:pt-[40px]">
                        <ul className="list-disc font-sans
                            text-[16px] leading-[24px] ml-[20px]
                            md:text-[24px] md:leading-[40px] md:ml-[28px]">
                            {data.sectionAplicar.list.map((item :any, index: any) => (
                                <li key={index}>{item}</li>
                            ))}
                        </ul>
                        <a target="_blank" rel="noreferrer" className="flex justify-center"
                            href="https://api.whatsapp.com/send?phone=51933509472&text=Hola,%20quiero%20m%C3%A1s%20informaci%C3%B3n%20sobre%20los%20productos%20y%20servicios%20de%20Ciclos%20Studio">
                            <BtnEscribenos2 text={data.section1.btn} />
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SectionAplicar
