import { Link } from 'react-scroll';

function BtnToTop() {
   return (

      <Link
         className="fixed bottom-[4%] right-[4%] !z-30 cursor-pointer !rounded-full !shadow-2xl"
         to="Inicio"
         spy={true}
         smooth={true}
         duration={500}
         offset={-132}>
         <img src='/svg/totop.svg' alt=''
            className='w-[30px] sm:w-[40px] md:w-[50px]' />
      </Link>

   );
}

export default BtnToTop;
