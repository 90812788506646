import { FloatingWhatsApp } from "react-floating-whatsapp";
interface Props {
   data: any
}
export default function BtnWhatsApp({ data }: Props) {
  return (
    <div className="fixed bottom-[4%] right-[4%] !z-30">
      <FloatingWhatsApp phoneNumber="+51933509472" accountName="Ciclos Studio" 
      chatMessage={data.btnWsp.message} statusMessage=""
      avatar="svg/ciclos-logo.jpg"
      placeholder={data.btnWsp.placeholder}/>
    </div>
  );
}
