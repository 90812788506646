import { NavLink } from "react-router-dom";

function BtnReturn() {
   return (

      <NavLink
         className="absolute !z-30 cursor-pointer select-none
         top-[16px] auxNav:top-[28px] sm:top-[50px] lg:top-[100px]
         left-[16px] md:left-8 auxHero:left-[6%] auxHero2:left-[20%] "
         to="/blog">
         <img src='/svg/return.svg' alt=""
            className='w-[16px] auxNav:w-[20px] md:w-[30px]'
         />
      </NavLink>

   );
}

export default BtnReturn;