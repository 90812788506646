interface Props {
    img: string;
    alt: string;
}

const Img = ({ img, alt }: Props) => {
    return (
        <img
            src={img} alt={alt}
            className="w-full object-cover
            h-[160px] smAux:h-[196px] sm:h-auto">
        </img>
    )
}

export default Img
