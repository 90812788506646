import BlogStructure from "../../components/structure/BlogStructure"
import H3text from "../../components/structure/sections/H3text"
import Img from "../../components/structure/sections/Img"

interface Props {
   data: any
}
const GlosarioUX = ({ data }: Props) => {
   return (
      <BlogStructure data={data} maxW="max-w-[680px]" aplicar
         bio={data.glosario.bio}
         title={data.glosario.hero.title}
         description={data.glosario.hero.description}
         text1={data.glosario.hero.text1}
         text2={data.glosario.hero.text2}
         hero={data.glosario.galery.hero}
         althero={data.glosario.galery.althero}>
         <img
            src={data.glosario.galery.img1}
            alt={data.glosario.galery.alt1} />
         <ol
            className="grid list-decimal
            text-[16px] leading-[24px] pl-[20px] gap-[16px]
            smAux:text-[22px] smAux:leading-[31px] smAux:pl-[28px] 
            sm:text-[24px] sm:leading-[32px] sm:pl-[30px] sm:gap-[32px]">
            <li>
               <H3text h3={data.glosario.h3text1.title}
                  text={data.glosario.h3text1.text} />
            </li>
            <li>
               <H3text h3={data.glosario.h3text2.title}
                  text={data.glosario.h3text2.text} />
            </li>
            <li>
               <H3text h3={data.glosario.h3text3.title}
                  text={data.glosario.h3text3.text} />
            </li>
            <li>
               <H3text h3={data.glosario.h3text4.title}
                  text={data.glosario.h3text4.text} />
            </li>
            <li>
               <H3text h3={data.glosario.h3text5.title}
                  text={data.glosario.h3text5.text} />
            </li>
            <li>
               <H3text h3={data.glosario.h3text6.title}
                  text={data.glosario.h3text6.text} />
            </li>
            <li>
               <H3text h3={data.glosario.h3text7.title}
                  text={data.glosario.h3text7.text} />
            </li>
            <Img
               img={data.glosario.galery.img2}
               alt={data.glosario.galery.alt2} />
            <li>
               <H3text h3={data.glosario.h3text8.title}
                  text={data.glosario.h3text8.text} />
            </li>
            <li>
               <H3text h3={data.glosario.h3text9.title}
                  text={data.glosario.h3text9.text} />
            </li>
            <li>
               <H3text h3={data.glosario.h3text10.title}
                  text={data.glosario.h3text10.text} />
            </li>
            <img
               alt={data.glosario.galery.img3}
               src={data.glosario.galery.img3} />
         </ol>
      </BlogStructure>
   )
}

export default GlosarioUX

