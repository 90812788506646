interface Props {
  text:any,
}

const BtnEscribenos: React.FC<Props> = ({text}) => {
  return (
    <button
        className="flex items-center justify-center bg-rosaC rounded-[60px]
        w-[184px] h-[58px] scale-95
        sm:w-[246px] sm:h-[69px]
        hover:translate-y-[-2px] hover:shadow-lg hover:scale-100
        transition-all ease-in">
        <p
            className="text-white uppercase
            font-jost font-medium text-[14px] smAux:text-[18px] mdAux:text-[20px]">
            {text}
        </p>
    </button>
  )
}

export default BtnEscribenos