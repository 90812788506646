import BlogStructure from "../../components/structure/BlogStructure"
import H3text from "../../components/structure/sections/H3text"
import Img from "../../components/structure/sections/Img"
import Text from "../../components/structure/sections/Text"

interface Props {
   data: any
}
const EstrategiasEfectivas = ({ data }: Props) => {
   const pl = "sm:pl-[38px]"
   return (
      <BlogStructure data={data} maxW="max-w-[720px]"
         bio={data.estrategias.bio}
         title={data.estrategias.hero.title}
         description={data.estrategias.hero.description}
         text1={data.estrategias.hero.text1}
         text2={data.estrategias.hero.text2}
         hero={data.estrategias.galery.hero}
         althero={data.estrategias.galery.althero}>
         <H3text h3={data.estrategias.h3text1.title}
            text={data.estrategias.h3text1.text} pl={pl} />
         <H3text h3={data.estrategias.h3text2.title}
            text={data.estrategias.h3text2.text} pl={pl} />
         <H3text h3={data.estrategias.h3text3.title}
            text={data.estrategias.h3text3.text} pl={pl} />
         <Img
            img={data.estrategias.galery.img1}
            alt={data.estrategias.galery.alt1} />
         <H3text h3={data.estrategias.h3text4.title}
            text={data.estrategias.h3text4.text} pl={pl} />
         <H3text h3={data.estrategias.h3text5.title}
            text={data.estrategias.h3text5.text} pl={pl} />
         <H3text h3={data.estrategias.h3text6.title}
            text={data.estrategias.h3text6.text} pl={pl} />
         <img
            src={data.estrategias.galery.img2}
            alt={data.estrategias.galery.alt2} />
         <Text text={data.estrategias.resumen} />
      </BlogStructure>
   )
}

export default EstrategiasEfectivas

