import { Link } from "react-scroll";

interface Props {
    number: number,
    onClick: () => void,
    isActive: boolean;
}

const BtnPagination = ({ number, onClick, isActive }: Props) => {
    return (
        <Link to="Cards"
            offset={-110}
            duration={400}
            smooth={true}
            onClick={onClick}
            className={`flex justify-center items-center rounded-full border-[1px] border-[#9B9262] 
            transition-all ease-in-out hover:bg-[#a19c81] hover:text-white
            w-[32px] h-[32px] sm:w-[40px] sm:h-[40px] shadow-md
                ${isActive ? 'bg-[#9B9262] text-white' : 'bg-white text-[#9B9262]'}`} >
            <p className="font-sans font-bold text-[16px] text-center select-none">
                {number}
            </p>
        </Link>
    )
}

export default BtnPagination
