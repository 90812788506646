import CardHeroBlog from "../components/cards/CardHeroBlog";
import { motion } from "framer-motion"
import PaginationBlog from "../components/structure/PaginationBlog";
import { Helmet } from "react-helmet";
interface Props {
   data: any
}

const PageBlog = ({ data }: Props) => {
   return (
      <motion.div
         initial={{ opacity: 0 }}
         animate={{ opacity: 1 }}
         transition={{ duration: 1.5 }}
         className="containerBlog pt-[24px] auxNav:pt-[50px] sm:pt-[78px] relative">
         <Helmet>
            <title>Blog by Ciclos Studio</title>
            <meta name="description" content={data.pageBlog.description} />
         </Helmet>
         <section id="Hero">
            <h1 className="pb-[24px] md:pb-[40px] z-20">BLOG</h1>
            <CardHeroBlog btn={data.pageBlog.btn} link={data.glosario.url}
               text={data.glosario.hero.title}
               theme1={data.pageBlog.theme1} theme2={data.pageBlog.theme2} />
         </section>
         <section id="Cards"
            className="w-full pb-[72px] mt-[40px] sm:mt-[32px] !z-20 relative">
            <h2 className="mb-[24px] sm:mb-[36px] !z-20">
               {data.pageBlog.title}
            </h2>
            <PaginationBlog data={data} />
         </section>
         <img src="/blog/decorations/rectangle-red.svg" alt="" className="absolute top-[10%] translate-x-[50%] right-0 !z-0" />
         <img src="/blog/decorations/rectangle-beige.svg" alt="" className="absolute top-[50%] translate-x-[-50%] left-0 !z-10" />
         <img src="/blog/decorations/circle2.svg" alt="" className="absolute top-[54%] translate-x-[50%] right-[50%] !z-10" />
         <img src="/blog/decorations/dots.svg" alt="" className="absolute top-[25%] translate-x-[-10%] z-10" />
         <img src="/blog/decorations/dots.svg" alt="" className="absolute top-[65%] right-[-15%] z-10 rotate-90" />
         <img src="/blog/decorations/circle-mini2.svg" alt="" className="absolute top-[68%] translate-x-[50%] right-[50%] z-10" />
         <img src="/blog/decorations/dots.svg" alt="" className="absolute translate-x-[50%] right-[10%] z-10 bottom-[10px] sm:bottom-[-50px]" />
      </motion.div>
   );
}

export default PageBlog