import BlogSwiper from "../swipers/BlogSwiper";


type Props = {
   data: any
};

const SectionFinal = ({ data }: Props) => {
   return (
      <>
         <section id="compartimos">
            <div className='w-[92%] mx-auto max-w-[1200px]'>
               <h2 className='text-center'>{data.section8.title}</h2>
               <p
                  className='p1 text-center mt-[16px] sm:mt-[8px] lg:mt-[22px]'>
                  {data.section8.text}
               </p>
               <div
                  className='mt-[24px] sm:mt-[32px]'>
                  <BlogSwiper data={data} />
               </div>
            </div>
         </section>
         {/**
          * <section id="suscribete"
            className='overflow-hidden'>
            <div
               className='containerW-1200  grid justify-center relative
               pt-[24px] smAux:pt-[40px] mdAux:pt-[40px] lg:pt-[80px]
               mdAux2:justify-start mdAux2:grid-cols-[1fr_356px] lg:grid-cols-2'>
               <div className='mx-[5%] smAux:mx-0'>
                  <h2 className='text-center mdAux2:text-start
                  text-[24px] mdAux:text-[34px] auxClients:text-[40px]'>
                     Suscríbete a nuestro blog
                  </h2>
                  <p className='pt-[16px] text-center mdAux2:text-start sm:pt-[18px] auxClients:pt-[24px]
                  text-[16px] auxClients:text-[22px] design:text-[24px] design:leading-[32px]'>
                     Recibe nuestros boletines con información valiosa
                  </p>
               </div>
               <div
                  className='self-end  smAux:mx-[8%] smAux1:mx-0  pb-[60px] smAux1:pb-[80px]'>
                  <FormSuscription />
               </div>
               <img alt=''
                  src='svg/decorations/suscribe.svg'
                  className='absolute bottom-0 right-0 -z-10
                  translate-y-[100px] translate-x-[140px] 
                  invisible mdAux2:visible'>
               </img>
            </div>
         </section>
          */}
      </>
   )
}

export default SectionFinal
