interface Props {
  h3: string;
  text: string;
  pl?: string;
}

const H3text = ({ h3, text, pl}: Props) => {
  return (
    <div>
      <h3>{h3}</h3>
      <p className={`text-[14px] leading-[20px] mt-[4px] !z-20
        sm:text-[20px] sm:leading-[36px] ${pl}`}>
        {text}
      </p>
    </div>
  )
}

export default H3text