import React from 'react'


interface HoverState {
    fillBg: string;
    fillIcon: string;
}

const LinkedIn = () => {
    const [hover, setHover] = React.useState<HoverState>({
        fillBg: '#fff',
        fillIcon: '#3A3A3A',
    })
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={36}
            height={36}
            fill="none"
            className='cursor-pointer'
            onMouseEnter={() => {
                setHover({
                    fillBg: '#FF0062',
                    fillIcon: '#FFF',
                })
            }}
            onMouseLeave={() => {
                setHover({
                    fillBg: '#fff',
                    fillIcon: '#3A3A3A',
                })
            }
            }
        >
            <g clipPath="url(#a)">
                <path
                    fill={hover.fillBg}
                    d="M18 36c9.941 0 18-8.06 18-18 0-9.941-8.059-18-18-18S0 8.059 0 18c0 9.94 8.059 18 18 18Z"
                />
                <path
                    fill={hover.fillIcon}
                    d="M28.755 19.448v7.421h-4.302v-6.924c0-1.738-.622-2.925-2.18-2.925-1.188 0-1.894.8-2.206 1.573-.114.277-.143.66-.143 1.049v7.227h-4.303s.058-11.727 0-12.94h4.303v1.833c-.008.015-.02.029-.028.043h.028v-.043c.572-.88 1.592-2.137 3.878-2.137 2.83 0 4.953 1.85 4.953 5.823ZM11.12 7.691c-1.472 0-2.434.966-2.434 2.235 0 1.243.935 2.237 2.378 2.237h.028c1.5 0 2.433-.994 2.433-2.237-.028-1.269-.933-2.235-2.405-2.235ZM8.941 26.869h4.301V13.93h-4.3v12.94Z"
                />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M0 0h36v36H0z" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default LinkedIn