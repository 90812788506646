
interface card {
    imgUrl: string,
    title: string,
    paragraph: String
}

const ComoAyudarteCard = (props: card) => {

    const styleCard = {
        border: "1px solid #F2F2F2",
        boxShadow: "0px 10px 50px rgba(0, 0, 0, 0.05)",
        borderRadius: "40px"
    }

    return (
        <div
            style={styleCard}
            className='grid justify-items-center
            w-[241px] p-[24px]
            sm:w-[296px]  bg-white
            smAux:w-[306px] smAux:p-[30px] '>
            <img
                src={props.imgUrl}
                alt={props.title}
                className="smAux:auto"
            />
            <h3
                className="text-center w-full
                smAux:text-[20px] smAux:mx-10 pt-[12px]
                smAux:py-[24px]">
                {props.title}
            </h3>
            <p
                className='text-center w-full
                text-[14px] leading-[18px] pt-[16px]
                smAux:text-[15px] smAux:leading-[22px] 
                sm:text-[16px] sm:leading-[24px]'>
                {props.paragraph}
            </p>
        </div>
    )
}

export default ComoAyudarteCard