import { useState } from 'react';
import CardPageBlog from '../cards/CardPageBlog';
import BtnPagination from '../buttons/BtnPagination';
import { Link } from 'react-scroll';

interface Props {
    data: any;
}

const PaginationBlog = ({ data }: Props) => {
    const itemsPerPage = 4
    const [currentPage, setCurrentPage] = useState(1);

    const filtro = Object.keys(data).filter(sectionKey => (
        data[sectionKey]?.hero?.title && data[sectionKey]?.hero?.text1
    ));
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentSectionKeys = filtro.slice(startIndex, endIndex);

    return (
        <div className='grid gap-[24px] sm:gap-[32px] !z-20'>
            <div
                className="grid gap-[16px] md:gap-[32px] !z-20">
                {currentSectionKeys.map((sectionKey: string) => (
                    <CardPageBlog
                        key={sectionKey}
                        link={data[sectionKey]?.url}
                        title={data[sectionKey]?.hero?.title}
                        img={data[sectionKey]?.galery?.hero}
                        text={data[sectionKey]?.hero?.text1}
                        theme1={data.pageBlog.theme1} theme2={data.pageBlog.theme2}
                    />
                ))}
            </div>
            <div className='z-20 flex justify-center items-center gap-[16px] sm:gap-[20px]'>
                <Link to="Cards"
                    offset={-110}
                    duration={400}
                    smooth={true}>
                    <img src='/svg/to.svg' alt=''
                        className='btnpagination rotate-180'
                        onClick={() => setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))} />
                </Link>
                {Array.from({ length: Math.ceil(filtro.length / itemsPerPage) }, (_, index) => (
                    <BtnPagination
                        key={index + 1}
                        onClick={() => setCurrentPage(index + 1)}
                        number={index + 1}
                        isActive={currentPage === index + 1}
                    />
                ))}<Link to="Cards"
                    offset={-110}
                    duration={400}
                    smooth={true}>
                    <img src='/svg/to.svg' alt=''
                        className='btnpagination'
                        onClick={() => setCurrentPage((prevPage) => Math.min(prevPage + 1, Math.ceil(filtro.length / itemsPerPage)))} />
                </Link>
            </div>
        </div>
    );
};

export default PaginationBlog;
