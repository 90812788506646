import React from "react"
interface HoverState {
   fillBg: string,
}
const Idiom = () => {
   const [hover, setHover] = React.useState<HoverState>({
      fillBg: '#2B2326',
  })
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width={38}
         height={24}
         fill="none"
         className='cursor-pointer
         transition-all ease-in'
         onMouseEnter={() => {
             setHover({
                 fillBg: '#FF0062',
             })
         }}
         onMouseLeave={() => {
             setHover({
                 fillBg: '#2B2326',
             })
         }
         }
      >
         <path
            stroke={hover.fillBg}
            strokeLinecap="square"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M1 1h36l-7 11 7 11H1V1Z"
         />
         <path
            fill={hover.fillBg}
            d="M10.061 9.09v1.988h3.206v1.848h-3.206v2.156h3.626V17h-6.02V7.172h6.02V9.09h-3.626ZM24.333 17H21.94l-4.004-6.062V17h-2.394V7.172h2.394l4.004 6.09v-6.09h2.394V17Z"
         />
      </svg>
   )
}
export default Idiom
