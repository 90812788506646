import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import { Grid, Pagination } from "swiper";
import { CardEquipo } from "../cards/CardEquipo";

export default function EquipoSwiper() {
  return (
    <>
      <Swiper
        style={{
          "--swiper-pagination-color": "#FF0062",
          "--swiper-pagination-bullet-inactive-color": "#E7F0FC",
          "--swiper-pagination-bullet-inactive-opacity": "1",
        }}
        slidesPerView={1}
        spaceBetween={24}
        pagination={{
          clickable: true,
        }}
        grid={{
          rows: 2,
          fill: 'row',
        }}
        breakpoints={{
          520: {
            slidesPerView: 2,
            spaceBetween: 32,
            grid: {
              rows: 2,
            },
          },
          415: {
            slidesPerView: 1.5,
            spaceBetween: 32,
            grid: {
              rows: 2,
            },
          },
          360: {
            slidesPerView: 1,
            spaceBetween: 24,
            grid: {
              rows: 2,
            },
          },
          200: {
            slidesPerView: 1,
            spaceBetween: 24,
            grid: {
              rows: 2,
            },
          },
        }}
        modules={[Grid, Pagination]}
        className="mySwiper mt-[32px] md:mt-[68px]"
      >
        <SwiperSlide>
          <CardEquipo name='Marialejandra Arrilucea' cargo='Product Designer' foto='home/equipo/mariale-arrilucea.png' />
        </SwiperSlide>
        <SwiperSlide>
          <CardEquipo name='Allison de la Cruz' cargo='Product Designer' foto='home/equipo/allison-delaCruz.png' />
        </SwiperSlide>
        <SwiperSlide>
          <CardEquipo name='Gonzalo Reyes' cargo='UX Project Manager' foto='home/equipo/gonzalo-reyes.png' />
        </SwiperSlide>
        <SwiperSlide>
          <CardEquipo name='Robert Quillas' cargo='Technical Leader' foto='home/equipo/robert-quillas.png' />
        </SwiperSlide>
        <SwiperSlide>
          <CardEquipo name='Heison Espinoza' cargo='Founder & PM' foto='home/equipo/heison-espinoza.png' />
        </SwiperSlide>
        <SwiperSlide>
          <div className="mb-[60px] md:mb-[72px]">
            <CardEquipo name='Natalia Diaz' cargo='Frontend' foto='home/equipo/natalia-diaz.png' />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="mb-[60px] md:mb-[72px]">
            <CardEquipo name='Diego bazan' cargo='UX/UI Designer' foto='home/equipo/diego-bazan.png' />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="mb-[60px] md:mb-[72px]">
            <CardEquipo name='Gema Berrocal' cargo='UI Designer' foto='home/equipo/gema-berrocal.png' />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="mb-[60px] md:mb-[72px]">
            <CardEquipo name='Brigette Sosa' cargo='UI Designer' foto='home/equipo/brigitte-sosa.png' />
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
