import BlogStructure from "../../components/structure/BlogStructure"
import H32text from "../../components/structure/sections/H32text"
import H3text from "../../components/structure/sections/H3text"
import Text from "../../components/structure/sections/Text"

interface Props {
    data: any
}
const Importancia = ({ data }: Props) => {
    return (
        <BlogStructure data={data} maxW="max-w-[670px]" aplicar
            bio={data.importancia.bio}
            title={data.importancia.hero.title}
            hero={data.importancia.galery.hero}
            althero={data.importancia.galery.althero}
            text1={data.importancia.hero.text1} >
            <H32text h3={data.importancia.h3text.title}
                text1={data.importancia.h3text.text1}
                text2={data.importancia.h3text.text2} />
            <img src={data.importancia.galery.img1}
                alt={data.importancia.galery.alt1} />
            <h2 className="h2blog">{data.importancia.beneficios.h2}</h2>
            <ol
                className=" grid gap-[16px] sm:gap-[32px] list-decimal
                font-jost font-bold 
                text-[16px] leading-[24px] pl-[20px]
                smAux:text-[22px] smAux:leading-[31px] smAux:pl-[28px] 
                sm:text-[24px] sm:leading-[32px] sm:pl-[30px]">
                <li>
                    <H3text h3={data.importancia.beneficios.h3text1.title}
                        text={data.importancia.beneficios.h3text1.text} />
                </li>
                <img alt={data.importancia.galery.alt2}
                    src={data.importancia.galery.img2} />
                <li>
                    <H3text h3={data.importancia.beneficios.h3text2.title}
                        text={data.importancia.beneficios.h3text2.text} />
                </li>
                <img alt={data.importancia.galery.alt3}
                    src={data.importancia.galery.img3} />
                <li>
                    <H3text h3={data.importancia.beneficios.h3text3.title}
                        text={data.importancia.beneficios.h3text3.text} />
                </li>
                <img alt={data.importancia.galery.alt4}
                    src={data.importancia.galery.img4} />
                <li>
                    <H3text h3={data.importancia.beneficios.h3text4.title}
                        text={data.importancia.beneficios.h3text4.text} />
                </li>
                <img alt={data.importancia.galery.alt5}
                    src={data.importancia.galery.img5} />
            </ol>
            <H3text h3={data.importancia.adicional.h3}
                text={data.importancia.adicional.text} />
            <Text text={data.importancia.resumen} />
        </BlogStructure>
    )
}

export default Importancia
