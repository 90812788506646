
import Facebook from '../components/svgs/Facebook'
import Instagram from '../components/svgs/Instagram'
import LinkedIn from '../components/svgs/LinkedIn'
interface Props {
   data: any
}
const Footer = ({ data }: Props) => {

  return (
    <footer className='!z-30 bg-[url(/public/home/bg-footer.png)] bg-cover bg-no-repeat text-white px-[5%] xl:px-0'>
      <div
        className='grid w-full !z-30
        border-b-[1px] py-[48px] border-[#727272]'>
        {/*
            className='max-w-[1200px] grid mx-auto
            border-b-[1px] py-[48px] border-[#727272]
            md:grid-cols-2 xl:py-[72px] xl:grid-cols-3 '>
        */}
        <div
          className='grid justify-items-center text-center'>
          {/*
            className=' justify-self-center text-center md:col-span-2
           xl:col-span-1 xl:justify-self-start xl:text-start'>
        */}
          <img alt='Ciclos Studio'
            src='/svg/logoWhite.svg'
            className='w-[212px] smAux:w-[266px] md:w-[255px]'>
          </img>
          <p className='my-[24px] text-[12px] sm:text-[14px] lg:text-[16px]
            leading-[28px] lg:leading-[28px]'>
            +51 933 509 472 <br />
            hola@ciclos.studio <br />
            {data.footer.text}
          </p>
          <ul
            className='flex gap-[16px] justify-center xl:justify-start'>
            <li>
              <a
                href="https://www.facebook.com/ciclos.studio"
                target="_blank" rel="noreferrer">
                <Facebook />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/ciclos.studio/"
                target="_blank" rel="noreferrer">
                <Instagram />
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/ciclos/"
                target="_blank" rel="noreferrer">
                <LinkedIn />
              </a>
            </li>
          </ul>
        </div>
        {/*
            const styleUl = {
              listStyleType: "disc",
              fontFamily: 'Nunito Sans',
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "22px"
            } 
        <div
          className=' mt-[36px] md:mt-[48px] xl:mt-0
          justify-start smAux:justify-self-center'>
          <h3
            className='lg:text-white
            smAux:text-[16px] mdAux:text-[20px] lg:text-[32px]'>
            Visita nuestro Blog
          </h3>
          <ul
            style={styleUl}
            className="text-[12px] px-[15px]  smAux:text-[14px] mt-[16px] mdAux2:mt-[20px] lg:mt-[16px]">
            <li className='mt-[12px] pl-[-12px]'>¿Cómo el Diseño UX puede ayudar a tu negocio?</li>
            <li className='mt-[12px] pl-[-12px]'>Prototipado rápido: Beneficios y oportunidades</li>
            <li className='mt-[12px] pl-[-12px]'>Glosario UX: Diseño de experiencia de usuario</li>
            <li className='mt-[12px] pl-[-12px]'>¿Qué es el Service Design o Diseño de Servicios?</li>
            <li className='mt-[12px] pl-[-12px]'>10 plantillas de wireframes en Figma Community</li>
          </ul>
        </div>
        <div
          className='px-[15px] mt-[24px] 
          smAux:justify-self-center smAux:translate-x-[-20px]
          md:translate-x-0 md:mt-[48px] 
          xl:justify-self-end xl:mt-0'>
          <h3
            className='lg:text-white translate-x-[-14px]
            smAux:text-[16px] mdAux:text-[20px] lg:text-[32px]'>
            Nuestros servicios
          </h3>
          <ul
            style={styleUl}
            className="text-[12px] leading-[16px] smAux:text-[14px] mt-[16px]  mdAux2:mt-[20px] lg:mt-[16px]">
            <li className='mt-[12px] pl-[-12px]'>Investigación con usuarios</li>
            <li className='mt-[12px] pl-[-12px]'>Prototipado rápido y Diseño UX</li>
            <li className='mt-[12px] pl-[-12px]'>Diseño de Interacción e Interfaces gráficas</li>
            <li className='mt-[12px] pl-[-12px]'>Desarrollo de Productos Digitales</li>
            <li className='mt-[12px] pl-[-12px]'>Diseño de nombres para marcas</li>
          </ul>
        </div>
        */}
      </div>
      <p
        className='text-center font-[500] font-jost py-[27px] 
        text-[12px] sm:text-[14px]'>
        {data.footer.credits} {" "} 
        <span className='font-bold transition-all ease-in
          text-amarilloC hover:text-white'>
          <a target='_blank' rel='noreferrer'
            href='https://heison.pe/portfolio/'>
            Heison Espinoza
          </a>
        </span>
      </p>
    </footer>
  )
}

export default Footer