import { useState } from 'react'
import BtnContáctanos from '../components/buttons/BtnContáctanos'
import { useMediaQuery } from 'react-responsive'
import { motion } from "framer-motion"
import { NavLink } from 'react-router-dom'
import Idiom from '../components/svgs/Idiom'
interface Props {
   changeLanguaje: () => void,
   data: any
}

const NavbarBlog = ({ changeLanguaje, data }: Props) => {
   const [active, setActive] = useState<Boolean>(false)
   const isDesktop = useMediaQuery({ query: '(min-width: 980px)' })
   const isTablet = useMediaQuery({ query: '(min-width: 640px)' })
   console.log(data)
   return (
      <header
         className='fixed top-0 flex items-center justify-center w-full shadow-md
         h-[50px] smAux1:h-[64px] auxNav:h-[74px] mdAux2:h-[88px] auxDesktopNav:h-[100px] bg-white z-50'>
         <nav className='containerNav flex items-center justify-between'>
            <NavLink
               to="/">
               <img alt='Ciclos Studio'
                  src='/svg/logo.svg'
                  className='cursor-pointer
                  w-[127px] 280:w-[160px] auxNav:w-[200px] md:w-[255px]'>
               </img>
            </NavLink>

            <div className='flex gap-[40px] items-center'>
               {isDesktop ?
                  <div className='flex gap-[50px] items-center'>
                     <NavLink to="/"
                        className="textNav">
                        {data.navbar.nav1}
                     </NavLink>
                     <NavLink to="/blog"
                        className='textNav'>
                        {data.navbar.nav4}
                     </NavLink>
                     <a 
                        className='select-none'
                        target="_blank" 
                        rel="noreferrer"
                        href="https://api.whatsapp.com/send?phone=51933509472&text=Hola,%20quiero%20m%C3%A1s%20informaci%C3%B3n%20sobre%20los%20productos%20y%20servicios%20de%20Ciclos%20Studio">
                        <BtnContáctanos text={data.navbar.btn} />
                     </a>
                     <div onClick={() => changeLanguaje()}>
                        <Idiom />
                     </div>
                  </div>
                  :
                  <img alt='menu'
                     src='/svg/burger.svg'
                     className='w-[24px] 
                     smAux:w-[24px] smAux:h-[18px]
                     auxNav:w-[34px] auxNav:h-[26px]
                     mdAux:w-[44px] mdAux:h-[33px]'
                     onClick={() => setActive(!active)}>
                  </img>
               }
            </div>
         </nav>
         {active && !isDesktop &&
            <motion.div
               initial={{ opacity: 0, x: 100 }}
               animate={{ opacity: 1, x: 0 }}
               exit={{ opacity: 0, x: 100 }}
               transition={{ duration: 1 }}
               className='grid bg-white z-10
               w-[224px] smAux:w-[304px] mdAux2:w-[320px]
               pb-[130px] smAux:pb-[121px]
               fixed top-0 right-0'>
               <div
                  className='flex justify-between items-center
                  pt-[32px] pb-[35px] px-[18px]
                  smAux:pt-[40px] smAux:pb-[33px] smAux:px-[16px]
                  mdAux2:pt-[30px] mdAux2:pb-[43px]'>
                  <img alt='Ciclos Studio'
                     src='/svg/logo.svg'
                     className='w-[80px] smAux:w-[100px] mdAux2:w-[147.5px]'>
                  </img>
                  <img alt='to close'
                     onClick={() => setActive(!active)}
                     src='/svg/x.svg'
                     className='cursor-pointer hover:bg-gray-200 hover:rounded-[100%]
                     w-[18px] smAux:w-[20px] mdAux:w-[24px]'>
                  </img>
               </div>
               <div
                  className='grid
                  pb-[16px] smAux:pb-[24px] mdAux:pb-0
                  gap-[16px] smAux:gap-[24px] mdAux2:gap-[16px]'>
                  <NavLink to="/"
                     onClick={() => setActive(false)}
                     className='textNav'>
                     {data.navbar.nav1}
                  </NavLink>
                  <NavLink to="/blog"
                     onClick={() => setActive(false)}
                     className='textNav'>
                     {data.navbar.nav4}
                  </NavLink>
               </div>
               {!isTablet &&
                  <div className='grid gap-[21px] smAux:gap-[24px]'>
                     <div
                        className='flex items-center px-[16px]
                        gap-[16px] smAux:gap-[24px]
                        py-[20px] smAux:py-[28px] 
                        border-y-[1px] border-[#ECEEF0]'>
                        <p className="text-negroC text-[14px] smAux:text-[16px]">
                           {data.navbar.languaje}
                        </p>
                        <div onClick={() => changeLanguaje()}>
                           <Idiom />
                        </div>
                     </div>
                     <div className='px-[16px]'>
                        <a target="_blank" rel="noreferrer"
                           href="https://api.whatsapp.com/send?phone=51933509472&text=Hola,%20quiero%20m%C3%A1s%20informaci%C3%B3n%20sobre%20los%20productos%20y%20servicios%20de%20Ciclos%20Studio">
                           <BtnContáctanos text={data.navbar.btn} />
                        </a>
                     </div>
                  </div>
               }
            </motion.div>

         }
         {active && !isDesktop &&
            <div
               className='bg-[#2B2326] opacity-[28%]
               fixed top-0 bottom-0 w-full h-[100vh]'>
            </div>
         }
      </header>
   )
}

export default NavbarBlog