interface Props {
    h3: string;
    text1: string;
    text2: string;
    pl?: string;
  }
  
  const H32text = ({ h3, text1, text2, pl }: Props) => {
    return (
      <div>
        <h3>{h3}</h3>
        <p className={`text-[14px] leading-[20px] mt-[8px]
          sm:text-[20px] sm:leading-[36px] ${pl}`}>
          {text1}
          <br />
          {text2}
        </p>
      </div>
    )
  }
  
  export default H32text
  